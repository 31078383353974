import { Title, NavBar, Card, CopyRight } from "../const"
import Container from "../containers";
import Button from "../button";
import BookNow from "../Book";


function Skin(props) {
    const { images } = props

    return (
     <div id="skin" className="ctxt-skin fstxt-s">
      <NavBar bgColor="skin" />

      <section id="hero" className="">
         <Title type="skin" className="ph5"> Tatyana's Alternative Medicine Esthetics </Title>

         <div style={{backgroundImage: `url(${images.room})`}} className="ph5 ctxt-primary pv8 grid gap-1 col-2 bg-img">
            <Card color="skin" className="txt-center col-start-2" maxWidth={500}>
               <div>
                  <p>
                     Specializing in Advanced SkinCare, Asian (Chinese) Herbal Medicine and Holistic Health. TAME TIME blends eastern philosophy with western technology.
                  </p>

                  <ul className="no-bullets pt1">
                     <li>~Micro Current - "The Non-Invasive Face Life"</li>
                     <li>~Acupressure Face-Lift</li>
                     <li>~Cosmetic Acupuncture</li>
                     <li>~MicroDermabrasion</li>
                     <li>~LED Light Therapy</li>
                     <li>~Peels</li>
                     <li>~Anti-Aging, Hyperpigmentation, Acne</li>
                     <li>~Complementary Alternative Medicine</li>
                     <li>~Asian Herbal Medicine</li>
                     <li>~and More</li>
                  </ul>
               </div>

               {/* Book Now Button */}
               <div class="pt1 uppercase underline">
                  <a href="https://ehr.unifiedpractice.com/Public/OnlineBooking?ClinicUid=5a7874fe-4f7c-4612-99d8-ac5016336bc0&LocationUid=76e4c4ba-c15f-4d19-974d-13691e39f372"><p>
                     Book Now!
                  </p></a>
               </div>
            </Card>

            <p className="col-span-2 pt2" style={{width: "80%", margin: "auto"}}>
               Featuring personalized protocols designed to
               clinically address patient/client's concerns. T.A.M.E.
               TIME blends eastern philosophy with western technology
               to design a holistic approach for the needs of your
               health, mind, body, skin, and life.
            </p>
         </div>         
      </section>

      <section id="mirconeedling">
         <Container type="box" className="grid gap-1 col-2">
            <div>
               <h2 className="mt3 pb6 fstxt-l papyrus">MicroNeedling </h2>

               <div className="skin mt2 mw-8 border-radius-sm" style={{padding:"1px"}}></div>

               <p className="mt1">
                  Microneedling, a minimally, invasive procedure, uses fine needles to create micro-injuries on the skin, stimulating collagen and elastin production. Known as collagen induction therapy, it is combined with customized serum containing growth factors, peptides, etc. This process improves skin firmness, smoothness, and reduces signs of aging like fine lines, wrinkles, and scars, with minimal downtime and versatility in addressing various skin issues.
               </p>
            </div>

            <img src={images.needling} alt="picture" className="max-width half-border-radius h-maxium-4 w-maxium-4 auto-margin"/>
         </Container>
         
         <Container type="box" className="grid gap-1 col-2 mt2">
            <ul className="no-bullets auto-margin">
               <li>
                  <h3>➢ Collagen & Elastin Boost</h3>
                  <p>noticeable increase in skin firmness and elasticity</p>
               </li>

               <li>
                  <h3>➢ Skin Texture Improvement</h3>
                  <p>visible reduction in hyperpigmentation, scars, and fine lines</p>
               </li>

               <li>
                  <h3>➢ Smoother, More Youthful Complexion</h3>
                  <p>impactful results through a non-aggressive and safe approach</p>
               </li>

               <li>
                  <h3>➢ Enhanced Product Absorption</h3>
                  <p>Allows deeper penetration of serums for more effective results</p>
               </li>
            </ul>

            <img src={images.effects} alt="Before and After" className="max-width col-start-1 row-start-1 pb2"/>
         </Container>

        
      </section>

      <section id="care" className="ph5 skin-light grid gap-1 col-2 pt1">
         <img className="auto-margin max-width w-maxium-4 border-radius-sm col-start-2 row-span-2" src={images.skincare} alt="Facial picture" />

            {/* Top Image */}
            <Container type="box" pos="row-start-1 auto-margin" className="txt-center">
                <h3 className="fstxt-l papyrus">Advanced Skin Care</h3>
                <h2 className="fstxt-m papyrus">Facials</h2>

                {/* Book Now button */}
                <Button href="https://ehr.unifiedpractice.com/Public/OnlineBooking?ClinicUid=5a7874fe-4f7c-4612-99d8-ac5016336bc0&LocationUid=76e4c4ba-c15f-4d19-974d-13691e39f372" color="skin">Book Now!</Button>
            </Container>

            <Card color="skin">
               <h4 className="fstxt-m papyrus">T.A.M.E. TIME: Tailored Skin Rejuvenation</h4>
               <p className="pt1">
                  Choose from 45, 60, 90, or 120 minutes of personalized facial and bodywork. Our T.A.M.E. TIME treatment combines advanced technologies (like Microcurrent, Microdermabrasion, LED), Korean and organic products, customized peels, and essential oil blends to:
               </p>

               <ul className="p-2">
                  <li><span className="bold">Exfoliate:</span> Remove dead skin cells for a smoother texture.</li>
                  <li><span className="bold">Treat:</span> Address specific skin concerns with targeted solutions.</li>
                  <li><span className="bold">Penetrate:</span> Deliver active ingredients deeply into the skin.</li>
                  <li><span className="bold">Oxygenate:</span> Boost skin vitality and radiance.</li>                  
               </ul>

               <p>Experience revitalized and rejuvenated skin with our comprehensive approach!</p>
               
            </Card>
      </section>

      <section id="microcurrent" className="mt3 grid gap-1 col-3">
         
         <Container type="box" alpha={0.8} pos="grid pt1 justify-left col-span-2 ml1 align-center ctxt-primary" bg={[166,166,166]}>
            <h2 className="fstxt-l papyrus">MicroCurrent</h2>
         </Container>

         {/* MOVE TO BELOW IMAGE BUT KEEP SAME STUCTURE ON PC AS NOW */}
         <Container type="emptybox" alpha={0.2} pos="row-span-3 auto-margin w-maxium-4" className="ctxt-dark box-shadow-3 border-radius-sm p-1" style={{maxHeight: "90%"}} bg={[166,166,166]}>
               <p className="pb2">
                  MicroCurrent technology boosts natural collagen and elastin production, and enhances blood flow, leading to healthier skin. Clinical studies show increased collagen by 14%, elastin by 48%, and blood circulation by 38% after 20 days.
               </p>
               <h4 className="pb1">Combining MicroCurrent with Injectables</h4>
               <p>
                  For optimal results, combine MicroCurrent with injectables. While fillers offer immediate volume and wrinkle reduction, MicroCurrent enhances muscle tone and stimulates natural collagen production. Many clients see prolonged effects of Botox when paired with MicroCurrent.
               </p>
         </Container>

         <Card className="skin wfit-content w-maxium-4 ctxt-primary auto-margin">
            <h4 className="pb1 fstxt-m papyrus">
               MicroCurrent Skin Rejuvenation: Your Non-Surgical Face Lift
            </h4>
            <p>
               Experience a youthful glow with MicroCurrent skin rejuvenation. This non-surgical treatment tones, lifts, and firms your skin using gentle electrical stimulation. Key benefits include:
            </p>
            <ul className="no-bullets pt1">
               <li>• Improve muscle tone in face and neck</li>
               <li>• Lift jowls and eyebrows</li>
               <li>• Exfoliated skin</li>
               <li>• Treatment for sun damage</li>
               <li>• Enhanced product absorption</li>
               <li>• Reduced wrinkles and fine lines</li>
               <li>• Better facial circulation</li>
            </ul>
         </Card>

         <img src={images.mircocurrent} alt="" className="max-width col-start-1 row-start-2 auto-margin border-radius-sm p-1" style={{height: "300px"}}/>

         <div className="col-span-2">
            {/* Small box */}
            <Container type="box" pos="">
               <h4 className="pb1">Recommended Treatments</h4>
               <ul className="ph5 pt1 pb1">
                  <li>
                     <strong>Initial Phase:</strong> 12-15 sessions (1-3 times per week)
                  </li>
                  <li>
                     <strong>Maintenance:</strong> Every 4 weeks for lasting results
                  </li>
               </ul>
               <p>
                  MicroCurrent offers visible improvements from the first session, with cumulative benefits over time. Schedule your appointment today to discover your personalized treatment plan.
               </p>
            </Container>

            {/* Warning Text */}
            <Container type="box">
               <p style={{fontSize: "12px",textAlign: "left", marginTop: "10px"}}>
                  <strong>Note:</strong> Not suitable for individuals with pacemakers, pregnancy, thrombosis, phlebitis, epilepsy, or cancer.
               </p>
            </Container>
         </div>
      </section>

      <section id="microDermabrasion" className="bg-img grid gap-1 p-1 mt3"
            style={{ backgroundImage: `url(${images.mircoDerm})` }}>
            
            <Container type="box" alpha={0.8} pos="grid justify-end pt1 pr2">
               <h2 className="fstxt-l papyrus w-minium-5">MicroDermabrasion</h2>
            </Container>

            <Container type="box" alpha={0.8} pos="grid justify-end pt1 pr2" className="w-maxium-5">
               <h4 className="pb1 underline">Refresh Your Skin</h4>
               <p className="pv2r">
                  Microdermabrasion is a top-notch treatment using a diamond-encrusted tool to gently exfoliate and suction away dead skin cells and impurities. This process unclogs pores, reduces inflammation, and enhances skin's overall health.
               </p>
               <h4 className="pt1">Benefits of Microdermabrasion:</h4>
               <ul className="no-bullets ph2 pb2">
                  <li>• Smooths age spots and blackheads</li>
                  <li>• Reduces hyperpigmentation</li>
                  <li>• Exfoliates for a fresh, glowing look</li>
                  <li>• Diminishes stretch marks</li>
                  <li>• Softens fine lines and wrinkles</li>
                  <li>• Minimizes enlarged pores</li>
                  <li>• Treats acne and acne scars</li>
               </ul>
            </Container>

           <p className="txt-center ctxt-primary bold fstxt-m">Experience a revitalized, radiant complexion with microdermabrasion!</p>
      </section>


      <section id="led" className="skin-light p-1 grid gap-1 col-2">
         <div className="pt2">
            <h2 className="fstxt-l papyrus">
               LED Light Therapy: Advanced Skin Rejuvenation
            </h2>
            <p className="pt2">
               Achieve smoother, tighter, and more radiant skin with LED Light Therapy. This safe and clinically proven treatment uses advanced, UV-free light technology to:
            </p>
            <ul className="no-bullets ph2 pt1">
               <li className="bold">
                  • Minimize fine lines, wrinkles, and age spots
               </li>
                <li>
                  • <strong>Stimulate collagen production</strong> for firmer skin
               </li>
                <li>
                  • <strong>Enhance skin appearance</strong> on the face, neck, and hands 
               </li>
            </ul>
            <h4 className="pt1">Benfiets:</h4>
            <ul className="no-bullets ph2 pt1">
               <li>
                  • <strong>Gradual Improvement:</strong> Noticeable results with continued use
               </li>
                <li>
                  • <strong>Painless:</strong> No recovery time needed
               </li>
                <li>
                  • <strong>Affordable:</strong> A cost-effective alternative to medical procedures
               </li>
            </ul>
            <p className="pt2">
               Experience the transformative effects of LED Light Therapy and enjoy a healthier, more youthful complexion
            </p>
         </div>

         <img src={images.shutterstock} alt="light photo" className="max-width p-1 auto-margin"/>

         <div className="">
            <h3 className="fstxt-l pb1 papyrus">Cosmetic Acupuncture: Natural Anti-Aging Solution</h3>
            <p>
               Discover the benefits of Cosmetic Acupuncture, a natural and non-invasive alternative to traditional cosmetic procedures. This treatment offers:
            </p>
            <ul className="no-bullets bold ph2 pt1">
                <li>• Reduction of fine lines and Softening of deeper wrinles</li>
                <li>• Lifting and toning muscles</li>
                <li>• Reduces hyperpigmentation</li>
                <li>• Improved complexion, texture, and hydration</li>
            </ul>
            <h4 className="pt2">Key Advantages:</h4>
            <ul className="ph5 p-1">
               <li>
                  <strong>Natural Results:</strong> Achieve subtle, gradual improvements without the side effects or downtime associated with more invasive procedures.
               </li>
               <li>
                  <strong>Minimal Risk:</strong> Most patients experience no complications, though occasional minor bleeding, bruising, or puffiness may occur.
               </li>
            </ul>
            <p>
               <strong>Eligibility:</strong> Not suitable for pregnant women, individuals with hemophilia or contagious skin conditions, those with severe deficiencies, or anyone who has had cosmetic injections or surgery within the past 2-6 weeks.
            </p>            
         </div>

         <img src={images.light} alt="light photo" className="max-width p-1 h-maxium-4 auto-margin col-start-1 row-start-2"/>

         <div className="col-span-2 pb2">
            <p className="txt-center bold fstxt-m">
               Experience a refreshed, youthful look with Cosmetic Acupuncture—your natural path to rejuvenated skin.
            </p>
         </div>
      </section>

      <BookNow
         txtColor={"ctxt-skin"} 
         cardColor={"skin"} 
         btnColor={"#646464"}
      />
      <CopyRight />
     </div>
    );
  }
  
  export default Skin;