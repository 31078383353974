import { NavBar, Title, CopyRight } from "../const";
import Button from "../button";
import bg from "../../data/bg-Logo.png"



function Life(props) {
    const { images } = props

    return (
        <div id="life" className="ctxt-life max-srceen-height bg-img fxtxt-s" style={{backgroundImage: `url(${bg})`}}>
            <NavBar />

            <section id="hero" className="ph5 light-bg content-center mh-95">
                <Title type="life"> Tatyana's Active-life Management Efficiency </Title>

                <div className="grid gap-1 p-1 col-3 mt3">
                    <img src={images.grocery} alt="" className="max-width" />
                    <Button color="life" className="auto-margin txt-center ctxt-primary w-maxium-3 fstxt-m" pos="content-center">
                        <ul className="no-bullets p-1">
                            <li>Grocery Shopping</li>
                            <li>Workshop</li>
                            <li>TBA</li>
                        </ul>
                    </Button>

                    <img src={images.mealPrep} alt="" className="max-width" />
                    <Button color="life" className="auto-margin txt-center ctxt-primary w-maxium-3 fstxt-m" pos="content-center">
                        <ul className="no-bullets p-1">
                            <li>Healthy Cooking</li>
                            <li>Workshop</li>
                            <li>TBA</li>
                        </ul>
                    </Button>

                    <img src={images.meals} alt="" className="max-width" />
                    <Button color="life" className="auto-margin txt-center ctxt-primary w-maxium-3 fstxt-m" pos="content-center">
                        <ul className="no-bullets p-1">
                            <li>Meal Prep</li>
                            <li>Workshop</li>
                            <li>TBA</li>
                        </ul>
                    </Button>
                </div>
            </section>

            <CopyRight />
        </div>
    )
}


export default Life