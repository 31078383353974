import { Card } from "./const"


const BookNow = ({txtColor, cardColor, btnColor, bgColor}) => {

    return (
        <section id="book-appointment" className={bgColor}>
            <div className="auto-margin mw-8">
                <h2 className={`underline txt-center pt2 ${txtColor} fstxt-l papyrus`}>Book Now With Us</h2>

                <div className="grid txt-center justify-center gap-1 col-3 pt2 fstxt-s">
                
                {/* BEVERLY HILLS LOCATION */}
                <Card color={`${cardColor}`} maxWidth={300}>
                    <h3>BEVERLY HILLS</h3>
                    <p>436 N. Roxbury Dr., Suite 201</p>
                    <a id="book_button" className="up-green-btn btn-large mt1" href="https://ehr.unifiedpractice.com/Public/OnlineBooking?ClinicUid=5a7874fe-4f7c-4612-99d8-ac5016336bc0&amp;LocationUid=5f2934a6-ffed-4721-8a1b-bec5872ac4ac" target="_blank" style={{background: btnColor, borderColor: "black"}}><span id="up_list">Book Appointment</span></a>
                </Card>

                {/* BAKERSFIELD LOCATION */}
                <Card color={`${cardColor}`} maxWidth={300}>
                    <h3>BAKERSFIELD</h3>
                    <p>8501 Camino Media, Suite 100</p>
                    <a id="book_button" className="up-green-btn btn-large mt1" href="https://ehr.unifiedpractice.com/Public/OnlineBooking?ClinicUid=5a7874fe-4f7c-4612-99d8-ac5016336bc0&amp;LocationUid=5f2934a6-ffed-4721-8a1b-bec5872ac4ac" target="_blank" style={{background: btnColor, borderColor: "black"}}><span id="up_list">Book Appointment</span></a>
                </Card>

                {/* VIRTUAL CARE */}
                <Card color={`${cardColor}`} maxWidth={300}>
                    <h3>VIRTUAL CARE</h3>
                    <p>
                    Discovery, Skincare, Consultaion,
                    TeleHealth, TeleMedicine, etc.
                    </p>
                    <a id="book_button" className="up-green-btn btn-large mt1" href="https://ehr.unifiedpractice.com/Public/OnlineBooking?ClinicUid=5a7874fe-4f7c-4612-99d8-ac5016336bc0&amp;LocationUid=5f2934a6-ffed-4721-8a1b-bec5872ac4ac" target="_blank" style={{background: btnColor, borderColor: "black"}}><span id="up_list">Book Appointment</span></a>
                </Card>
                </div>
            </div>
        </section>
    )
}


export default BookNow